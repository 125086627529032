import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Heading, TextMono, Text, Button } from '@atoms';
import {
  HeroContainer,
  Container,
  TextContainer,
  Gradient,
  CTAContainer,
} from "./styles/HeroFullWidth.styled"
import OptimizedImage from "../../utils/optimizedImage";
import Breadcrumbs from "./components/Breadcrumbs";
import { BreadcrumbContainer } from "./styles/Breadcrumbs.styled";

const HeroCallToAction = ({ button, type, index }) => {
  let btnVariant

  if (type.includes('blue') && index === 0) {
    btnVariant = 'secondary'
  } else {
    btnVariant = button?.variant
  }

  return (
    <Button ctaVariant={btnVariant} ctaLink={button?.link}>
      {button?.label}
    </Button>
  )
}

const HeroFullWidth = ({ component, location, seoBreadcrumbs }) => {
  const {
    type,
    callsToAction,
    subheading,
    kicker,
    heading,
    headingSize,
    breadcrumbs,
    breadcrumbTitle,
  } = component

  return (
    <HeroContainer
      data-comp={HeroFullWidth.displayName}
    >
      <OptimizedImage image={component?.featuredImage?.gatsbyImageData} src={component?.featuredImage?.file?.url} alt="" loading="eager" className="background-image" />
      {breadcrumbs && <BreadcrumbContainer><Breadcrumbs background={"dark"} location={location} seoBreadcrumbs={seoBreadcrumbs} breadcrumbTitle={breadcrumbTitle} />
      </BreadcrumbContainer>}
      <Container type={type}>
        <TextContainer type={type}>
          {kicker && <TextMono style={{ color: 'white', paddingBottom: '12px' }}>{kicker}</TextMono>}
          {heading && <Heading headingSize={headingSize} style={{ color: 'white', paddingBottom: '16px' }}>{heading}</Heading>}
          {subheading?.raw && <Text pb={6} headingSize={headingSize} style={{ color: 'white' }}>{documentToReactComponents(JSON.parse(component?.subheading?.raw))}</Text>}
          <CTAContainer style={{ display: 'flex', margin: '0 auto' }} type={type}>
            {callsToAction && callsToAction[0]?.link && callsToAction.map((btn, index) =>
              <HeroCallToAction button={btn} index={index} type={type} />
            )}
          </CTAContainer>
        </TextContainer>
      </Container>
      <Gradient type={type} />
    </HeroContainer>
  )
}

export default HeroFullWidth
HeroFullWidth.displayName = "HeroFullWidth"
